require('admin-lte')

$(function () {
    $(document).on('shown.lte.pushmenu', function () {
        document.cookie = "sidebar=visible";
    });

    $(document).on('collapsed.lte.pushmenu', function () {
        document.cookie = "sidebar=hidden";
    });

    if (getCookie('sidebar') == 'hidden') {
        $('[data-widget="pushmenu"]').PushMenu('collapse');
    }
})

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
